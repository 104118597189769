import React, { useState } from 'react';
import { Link as LinkElemet } from 'react-scroll';
import Icon from '@components/Icon';
import NavList from '@components/nav-list';
import Information from '@components/information';
import Link from '@components/link';
import { HEADER_NAV } from '@information-texts/HeaderNav';
import { dataNavbarList } from '.';
import { LinkIcons } from '@components/Icon/Icon';
import { ROUTES } from '@components/footer';

const HeaderNav: React.FC = () => {
    const [openMenu, setOpenMenu] = useState(false);
    return (
        <div className={`header-nav fixed top-0 z-30 ${openMenu && 'xs:h-auto xs:items-start xs:pt-2.5 xs:flex-col'}`}>
            <Icon name="navbarLogoCcxc" className="w-20 h-8 xs:w-10 xs:h-5 xs:absolute xs:left-8.5" />
            <Icon
                name={openMenu ? 'closeHeader' : 'menu'}
                className="absolute invisible cursor-pointer w-7 h-7 right-4 xs:visible"
                onCLick={(): void => setOpenMenu(!openMenu)}
            />

            <NavList
                links={dataNavbarList}
                classNameNavlist={`lg:flex md:flex justify-between max-w-178.75 w-full  ${
                    openMenu ? 'xs:visible xs:flex xs:flex-col xs:mt-12 xs:items-center xs:w-full' : 'xs:invisible'
                }`}
                classContainerList="flex"
                classNameList={`mr-1.5 ${openMenu && 'xs:mb-4.5'}`}
            />
            <div
                className={`flex w-0 h-0 absolute invisible justify-evenly ${
                    openMenu && 'xs:h-full xs:w-full xs:relative xs:visible'
                }`}
            >
                <LinkIcons nameIcon="logoLinkedinHeader" href={ROUTES.LINKEDIN} classIcon="icon-social" target="_blank" />
                <LinkIcons nameIcon="logoInstagramHeader" href={ROUTES.INSTAGRAM} classIcon="icon-social" target="_blank" />
                <LinkIcons nameIcon="logoFacebookHeader" href={ROUTES.FACEBOOK} classIcon="icon-social" target="_blank" />
                <LinkIcons nameIcon="logoTwitterHeader" href={ROUTES.TWITTER} classIcon="icon-social" target="_blank" />
            </div>
            <Information
                classNameContainer={`absolute invisible w-0 lg:h-0${openMenu && 'xs:h-full xs:w-full xs:relative xs:visible'}`}
                description={HEADER_NAV.DESCRIPTION}
            />
            <div
                className={`absolute flex invisible w-0 lg:h-0 ${
                    openMenu && 'xs:w-full xs:h-full xs:relative xs:visible xs:mb-7 xs:justify-center'
                }`}
            >
                <Link href={ROUTES.TERMS} text="Términos y condiciones" target="_blank" outside classes="text-white text-1.5xs" />
                <span className="text-white text-1.5xs mx-1">•</span>
                <Link href={ROUTES.DATA_TREATMENT_PRIVATE_THIRD_PARTIES} text="Política" target="_blank" outside classes="text-white text-1.5xs" />
                <span className="text-white text-1.5xs mx-1">•</span>
                <LinkElemet offset={-50} smooth={true} to="contact-us" className="text-white text-1.5xs">
                    Sugerencias y reclamos
                </LinkElemet>
            </div>
        </div>
    );
};

export default HeaderNav;
