import React, { Fragment } from 'react';
import Icon from '@components/Icon';
import { ICard, IDataCard } from '.';

const CardMain: React.FC<ICard> = ({
    classContainerCard,
    data,
    classContainerIcon,
    classTitle,
    classDescription,
    classIcon,
    classSubTilte,
}) => {
    return (
        <div className={`shadow-templateDesign ${classContainerCard}`}>
            {data?.map((dataCard: IDataCard, index) => (
                <Fragment key={index}>
                    <div className={`flex ${classContainerIcon}`}>
                        <Icon name={dataCard.icon} className={classIcon} format={dataCard.formatPng ? 'png' : 'svg'} />
                        <h3 className={`font-poppins ${classTitle}`}>{dataCard.title}</h3>
                        {dataCard.subTitle && <h4 className={`font-poppins ${classSubTilte}`}>{dataCard.subTitle}</h4>}
                    </div>
                    {dataCard.text && <p className={`font-poppins ${classDescription}`}>{dataCard.text}</p>}
                </Fragment>
            ))}
        </div>
    );
};

export default CardMain;
