import React, { Fragment, useState, useEffect } from 'react';
import CardMain, { IDataCard } from '@components/card-main';
import Icon from '@components/Icon';
import { constant, dataCardTestimony, newDataCardTestimony } from '..';
import { ICarouselTestimony } from '.';

const CarouselTestimony: React.FC<ICarouselTestimony> = ({ sizeScreen }) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [selectedMove, setSelectedMove] = useState<number>(0);
    const [dataCarousel, setDataCarousel] = useState(dataCardTestimony);
    const [dataChange] = useState(newDataCardTestimony);
    const [marketPointIndex, setMarketPointIndex] = useState(0);

    const check = (index: number): void => {
        setSelectedIndex(index);
    };

    useEffect(() => {
        sizeScreen < constant.sizeScreen
            ? setDataCarousel([...dataCardTestimony, ...newDataCardTestimony])
            : setDataCarousel(dataCardTestimony);
    }, [sizeScreen]);

    const clickLeft = (itemIndex: number, idItem: string): void => {
        const elemento = document.getElementById(idItem);
        const posicion = elemento?.getBoundingClientRect();
        const data: IDataCard[] = [];
        if (posicion && posicion?.height < constant.minTop) {
            const newData = dataCarousel.filter((item, index) => {
                switch (itemIndex) {
                    case 0:
                        if (
                            posicion?.left < constant.minLeft
                                ? index !== itemIndex + 2
                                : posicion?.left > constant.minLeft && index !== itemIndex + 1
                        ) {
                            return item;
                        } else {
                            data.push(item);
                        }
                        break;

                    default:
                        if (
                            posicion?.left < constant.minLeft
                                ? index !== itemIndex - 1
                                : posicion?.left > constant.minLeft && itemIndex === 2
                                ? index !== itemIndex - 2
                                : index !== itemIndex + 1
                        ) {
                            return item;
                        } else {
                            data.push(item);
                        }
                        break;
                }
            });

            if (itemIndex !== 0) {
                posicion?.left < constant.minLeft
                    ? newData.splice(itemIndex - 1, 0, newDataCardTestimony[0])
                    : posicion?.left > constant.minLeft && itemIndex === 2
                    ? newData.splice(itemIndex - 2, 0, newDataCardTestimony[newDataCardTestimony.length - 1])
                    : posicion?.left > constant.minLeft &&
                      newData.splice(itemIndex + 1, 0, newDataCardTestimony[newDataCardTestimony.length - 1]);
            } else {
                posicion?.left < constant.minLeft
                    ? newData.splice(itemIndex + 2, 0, newDataCardTestimony[0])
                    : posicion?.left > constant.minLeft &&
                      newData.splice(itemIndex + 1, 0, newDataCardTestimony[newDataCardTestimony.length - 1]);
            }
            setDataCarousel(newData);
            if (posicion?.left < constant.minLeft) {
                dataChange.shift();
                dataChange.push(data[0]);
            } else if (posicion?.left > constant.minLeft) {
                dataChange.pop();
                dataChange.unshift(data[0]);
            }
        }
    };

    useEffect(() => {
        let count =  1;
        setInterval(() => {
            const elementClick = document.getElementsByClassName('manual');
            if (sizeScreen > constant.sizeScreen) {
                const elemento = document.getElementById(
                    `slide-${+elementClick[0].id.substr(-1) === 3 ? 1 : +elementClick[0].id.substr(-1) + 1}`
                );
                elemento?.click();
            } else {
                [...dataCardTestimony, ...newDataCardTestimony].length === count ? (count = 0) : '';
                setSelectedMove(count);
                count = count + 1;
            }
        }, 5000);
    }, []);

    return (
        <div className="flex flex-wrap justify-center w-full xs:relative mt-7">
            <div className="flex flex-col items-center max-w-271.5 w-full text-center mx-14">
                {sizeScreen < constant.sizeScreen && (
                    <Icon
                        name="arrowLeftGreen"
                        className="z-20 icon-arrow-responsive left-4 xs:top-36"
                        onCLick={(): void => {
                            selectedMove > 0 && setSelectedMove(selectedMove - 1);
                        }}
                    />
                )}
                <section id="slider" className="inline-flex items-center justify-center flex-shrink-0 w-full slider">
                    {dataCarousel.map((itemSlide, indexSlide) => (
                        <Fragment key={indexSlide}>
                            <input
                                type="radio"
                                name="slider"
                                id={`slider-input-${indexSlide + 1}`}
                                className={`radio slider-input-${indexSlide + 1}`}
                                defaultChecked={selectedMove === indexSlide}
                                onClick={(): void => {
                                    check(indexSlide);
                                    setMarketPointIndex(itemSlide.indexPoint || 0);
                                }}
                            />
                        </Fragment>
                    ))}
                    {dataCarousel.map(
                        (itemSlide, indexSlideLabel) =>
                            (sizeScreen > constant.sizeScreen || indexSlideLabel === selectedMove) && (
                                <Fragment key={indexSlideLabel}>
                                    <label
                                        htmlFor={`slider-input-${indexSlideLabel + 1}`}
                                        className={`w-full slide-${indexSlideLabel + 1} ${
                                            indexSlideLabel === selectedIndex ? 'manual' : ''
                                        }`}
                                        onClick={(): void => {
                                            clickLeft(indexSlideLabel, `slide-${indexSlideLabel + 1}`);
                                        }}
                                        id={`slide-${indexSlideLabel + 1}`}
                                    >
                                        <CardMain
                                            data={[itemSlide]}
                                            classContainerCard={`bg-gray-light py-7 xs:px-7 xs:h-81.75 xs:pb-11.5 xs:pt-7 px-12 relative w-full rounded-lg ${
                                                indexSlideLabel !== selectedIndex ? 'h-76.5' : 'h-81.75 pb-12'
                                            }`}
                                            classTitle="mt-2 text-blue text-xxl xs:text-lg font-semibold"
                                            classContainerIcon="flex-col items-center mb-2"
                                            classIcon="w-30 h-30 xs:w-20 xs:h-20 rounded-full"
                                            classSubTilte="text-green xs:text-sm font-bold"
                                            classDescription="text-gray-dark text-xtinyx xs:text-xs"
                                        />
                                    </label>
                                </Fragment>
                            )
                    )}
                    <ul className="absolute flex justify-between w-full px-32 xs:px-3 bottom-6">
                        {[...dataCardTestimony, ...newDataCardTestimony].map((list, indexList) => (
                             <li
                             className={`w-2.5 h-2.5 bg-blue rounded-full ${
                                 indexList === (sizeScreen > constant.sizeScreen ? marketPointIndex : selectedMove)
                                     ? 'bg-blue'
                                     : 'bg-white'
                             }`}
                             key={indexList}
                         />
                        ))}
                    </ul>
                </section>
                {sizeScreen < constant.sizeScreen && (
                    <Icon
                        name="arrowRightGreen"
                        className="icon-arrow-responsive right-4 xs:top-36"
                        onCLick={(): void => {
                            selectedMove < dataCarousel.length - 1 && setSelectedMove(selectedMove + 1);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default CarouselTestimony;
