import React from 'react';
import { ICheckboxProps } from '.';

import './Checkbox.scss';

export const Checkbox: React.FC<ICheckboxProps> = ({
    label = '',
    checked = false,
    onChange = (): void => {},
    classContainer = '',
    classLabel = '',
    classCheck = '',
    name = '',
    disabled = false,
    isFather = false,
}) => {
    return (
        <label className={`container ${classContainer} container--${isFather ? 'father' : 'child'}`}>
            <span className={classLabel}>{label}</span>
            <input type="checkbox" checked={checked} onChange={onChange} name={name} disabled={disabled} />
            <span className={`checkmark ${classCheck}`} />
        </label>
    );
};

export const CheckboxMain: React.FC<ICheckboxProps> = ({
    name = '',
    label = '',
    checked = false,
    onChange = (): void => {},
    classBox = '',
    classContainer = '',
    classCheck = '',
    disabled = false,
    classSubcategory = '',
}) => {
    return (
        <div className={`wrapper-box ${classBox}`}>
            <Checkbox
                name={name}
                label={label}
                checked={checked}
                onChange={onChange}
                classContainer={`container--standard ${classContainer}`}
                classCheck={classCheck}
                disabled={disabled}
                classSubcategory={classSubcategory}
            />
        </div>
    );
};
