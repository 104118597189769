import React from 'react';
import { IInformationProps } from '.';
import './Information.scss';

const Information: React.FC<IInformationProps> = ({
    title,
    description,
    color = 'gray-dark',
    classNameContainer = '',
    classNameTitle = '',
    classNameDescription = '',
}) => {
    return (
        <div className={`information ${classNameContainer}`}>
            <h4 className={`information__title text-${color} text-lg cursor-default ${classNameTitle}`}>{title}</h4>
            <div className={`information__description ${classNameDescription}`}>{description}</div>
        </div>
    );
};
export default Information;
