import React, { Fragment, useState } from 'react';
import { Link as LinkElemet } from 'react-scroll';
import Icon from '@components/Icon';
import Link from '../link';
import { INavList } from '.';
import './NavList.scss';

const NavList: React.FC<INavList> = props => {
    const { links, classNameNavlist = '' } = props;
    return (
        <ul className={classNameNavlist}>
            {links.map((link, index) => (
                <Fragment key={index}>
                    <List
                        name={link.text}
                        isIcon={link.isIcon}
                        listLink={link?.listLink}
                        nameElement={link.nameElement}
                        offSetLink={link.offSetLink}
                        {...props}
                    />
                </Fragment>
            ))}
        </ul>
    );
};

export default NavList;

export const List: React.FC<INavList> = ({
    name,
    isIcon,
    classNameList,
    classContainerList,
    listLink,
    nameElement,
    offSetLink,
}) => {
    const [openSelect, setOpenSelect] = useState(false);

    return (
        <>
            {isIcon ? (
                <div
                    className={`xs:flex xs:flex-wrap xs:justify-center xs:w-full ${classContainerList}`}
                    onClick={(): void => {
                        setOpenSelect(!openSelect);
                    }}
                >
                    <li className={`li ${classNameList}`}>{name}</li>
                    <Icon name="arrowDownWhite" className="xs:mb-4.5 cursor-pointer" />
                    {openSelect && (
                        <div className="class-select-nav shadow-selectNav xs:shadow-none ">
                            {listLink &&
                                listLink?.map((list, index) => (
                                    <Link key={index} href={list.href || ''} text={list?.name || ''} outside />
                                ))}
                        </div>
                    )}
                </div>
            ) : (
                <LinkElemet offset={offSetLink} smooth={true} to={nameElement || ''} className={`li ${classNameList}`}>
                    {name}
                </LinkElemet>
            )}
        </>
    );
};
